<template>
  <div class="left-menu">
    <div class="container-fluid">
      <div class="mb-3">
        <select-district />
      </div>
      <div class="mb-3">
        <select-measurement-type />
      </div>
      <div class="mb-3">
        <select-year />
      </div>
      <div class="mb-3">
        <select-data-source-type />
      </div>
      <div class="mb-3">
        <upload-file />
      </div>

      <b-button
        variant="primary"
        class="mr-2"
        @click="sendData"
        :disabled="!selectedYear || !selectedDistrict || !selectedMeasurementType || !selectedDataSourceType"
      >
        Import
      </b-button>
      <b-button variant="danger" @click="clearForm">Reset</b-button>
    </div>

    <div v-if="resultStatistic" class="container-fluid mt-5">
      <b-alert variant="success" show>Number of new records inserted: {{ resultStatistic.importedRows }}</b-alert>
      <b-alert show>Number of records updated: {{ resultStatistic.updatedRows }}</b-alert>
      <b-alert show variant="warning">
        <i class="fa-solid fa-eye" v-b-toggle.collapse-info />
        Number of records not imported: {{ resultStatistic.skippedRows }}

        <b-collapse id="collapse-info" class="mt-2">
          <b-card>
            <p v-for="(row, rowNumber) in resultStatistic.notifications" class="card-text">Row Number - {{ rowNumber }}, Student State Id - {{ row }}</p>
          </b-card>
        </b-collapse>
      </b-alert>

    </div>
  </div>
</template>

<script>
import SelectDistrict from "@/components/filters/SelectDistrict"
import SelectYear from "@/components/filters/SelectYear"
import SelectMeasurementType from "@/components/filters/SelectMeasurementType"
import SelectDataSourceType from "@/components/filters/SelectDataSourceType"
import UploadFile from "@/components/filters/UploadFile"

export default {
  name: 'LeftFilters',
  components: {
    SelectDistrict,
    SelectYear,
    SelectMeasurementType,
    SelectDataSourceType,
    UploadFile
  },
  data () {
    return {
      resultStatistic: null
    }
  },
  mounted () {
    // this.getInitData()
  },
  computed: {
    selectedDataSourceType () {
      return this.$store.getters['filters/getSelectedSourceType']
    },
    selectedDistrict () {
      return this.$store.getters['filters/getSelectedDistrict']
    },
    selectedYear () {
      return this.$store.getters['filters/getSelectedYear']
    },
    selectedMeasurementType () {
      return this.$store.getters['filters/getSelectedMeasurementType']
    },
    selectedFile () {
      return this.$store.getters['filters/getProcessFile']
    }
  },
  methods: {
    clearForm () {
      this.$store.commit('filters/selectSourceType', null)
      this.$store.commit('filters/selectDistrict', null)
      this.$store.commit('filters/selectYear', null)
      this.$store.commit('filters/selectMeasurementType', null)
      this.$store.commit('filters/setProcessFile', null)
    },
    getInitData () {
      this.$store.commit('setLoader', true)
      this.$store.dispatch('getInitData')
        .then((response) => {
          this.$store.commit('setInitData', response.data.data.initializationData)
          this.$store.commit('setLoader', false)
        })
        .catch((error) => {
          this.$store.commit('setLoader', false)
        })
    },
    sendData () {
      const DataToServer = new FormData()
      DataToServer.append('importer', 'ScoresImport')
      DataToServer.append('selectedDataSourceType', JSON.stringify(this.selectedDataSourceType))
      DataToServer.append('selectedDistrict', JSON.stringify(this.selectedDistrict))
      DataToServer.append('selectedYear', JSON.stringify(this.selectedYear))
      DataToServer.append('selectedMeasurementType', JSON.stringify(this.selectedMeasurementType))
      for (const pair of this.selectedFile.entries()) {
        DataToServer.append(pair[0], pair[1]);
      }
      this.$store.commit('setLoader', true)
      this.$store.dispatch('importData', DataToServer)
        .then((response) => {
          this.resultStatistic = response.data.data
          this.$store.commit('setLoader', false)
        })
        .catch((err) => {
          this.$store.commit('setLoader', false)
          this.errorNotification(err.response.data.message, 3)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
